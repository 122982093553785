<script lang="ts">
	import { i18n } from "$lib/i18n";
	import { ParaglideJS } from "@inlang/paraglide-sveltekit";
	import "$src/scss/index.scss";
	import "$src/app.css";
	import LogoFull from "$lib/components/LogoFull.svelte";
	let { children } = $props();
</script>

<ParaglideJS {i18n}>
	<div class="min-h-svh column space-between | font-smoothing">
		<div
			class="fixed top-0 left-0 right-0 z-everest overflow-hidden bg-[repeating-linear-gradient(-45deg,#fbbf24,#fbbf24_10px,#000_10px,#000_20px)]"
		>
			<div class="shadow-[0_0_20px_5px_rgba(0,0,0,0.8)]">
				<div
					class="animate-marquee whitespace-nowrap py-1.5 font-mono text-sm font-bold [text-shadow:2px_2px_0_#000,-2px_2px_0_#000,2px_-2px_0_#000,-2px_-2px_0_#000]"
				>
					<span class="inline-block px-4">THIS SITE IS A WIP</span>
					<span class="inline-block px-4">–</span>
					<span class="inline-block px-4">THIS SITE IS A WIP</span>
					<span class="inline-block px-4">–</span>
					<span class="inline-block px-4">THIS SITE IS A WIP</span>
					<span class="inline-block px-4">–</span>
					<span class="inline-block px-4">THIS SITE IS A WIP</span>
					<span class="inline-block px-4">–</span>
					<span class="inline-block px-4">THIS SITE IS A WIP</span>
					<span class="inline-block px-4">–</span>
				</div>
			</div>
		</div>

		<div class="h-9"></div>

		<header class="row items-end space-between | pt-4 pb-2 px-4">
			<div class="row center-v space-x-1">
				<div class="w-6 hidden">
					<img
						src="/img/mind-computer-head.jpg"
						alt="mind.computer"
					/>
				</div>
				<a
					href="/"
					class="text-white text-xl leading-none font-logo"
				>
					<LogoFull class="text-white  w-24" />
				</a>
			</div>

			<div class="font-text text-xs text-white leading-none pb-1">
				it's just one.
			</div>
		</header>

		<div class="flex-grow flex-1 | column">
			{@render children()}
		</div>

		<footer class="text-center text-mc-grey-400 font-logo text-sm | py-2">
			– Be human. Be kind. Do better.
		</footer>
	</div>
</ParaglideJS>
